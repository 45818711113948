<app-dialog-container>
  <ng-container title>
    <h4 class="text-center">{{ data.title }}</h4>
  </ng-container>
  <ng-container content>
    @if(data.content){
    <div class="text-center">
      {{ data.content }}
    </div>
    <div class="bs-xs-30"></div>
    }
    <div class="dialog-btn-align">
      <button class="app-button btn-lg btn-outlined" mat-dialog-close>
        {{ data.cancelBtn ? data.cancelBtn : 'Cancel' }}
      </button>
      @if(!data.withoutConfirm){
      <button class="app-button btn-lg btn-secondary" (click)="confirm()">
        {{ data.confirmBtn ? data.confirmBtn : 'Confirm' }}
      </button>
      }
    </div>
  </ng-container>
</app-dialog-container>
