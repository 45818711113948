import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private messageService = inject(MessageService);

  handleError<T>(
    operation?: string | null,
    result?: T
  ): (error: any) => Observable<T> {
    return (error: HttpErrorResponse): Observable<T> => {
      if (operation) {
        this.messageService.setInvalidMessage(operation);
      } else {
        this.messageService.setInvalidMessage(error?.error?.message || '');
      }
      return result ? of(result as T) : throwError(error);
    };
  }
}
