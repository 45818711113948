import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HeaderMessage } from '../models/models';
import { APP_HTTP_ERROR_MESSAGE } from '../constants/config';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private resetMessage: HeaderMessage = {
    message: '',
    className: ''
  };
  headerMessage = new BehaviorSubject<HeaderMessage>(this.resetMessage);
  private messageTimeout: any;

  setMessage(value: string): void {
    this.formatMessage(value);
  }

  setInvalidMessage(value?: string): void {
    this.formatMessage(value || APP_HTTP_ERROR_MESSAGE, 'error-msg');
  }

  setSuccessMessage(value: string): void {
    this.formatMessage(value, 'success-msg');
  }

  private formatMessage(value: string, type = 'warning-msg'): void {
    this.headerMessage.next({
      message: value,
      className: type
    });
    clearTimeout(this.messageTimeout);
    this.messageTimeout = setTimeout(() => {
      clearTimeout(this.messageTimeout);
      this.headerMessage.next(this.resetMessage);
    }, 5000);
  }
}
