import { Pipe, PipeTransform } from '@angular/core';
import { UploadFile } from '../../core/models/models';

@Pipe({
  name: 'altImage',
  standalone: true
})
export class AltImagePipe implements PipeTransform {
  transform(value: UploadFile | undefined | null | string): string {
    if (typeof value === 'string') {
      return value;
    }
    return value?.alt_text || '';
  }
}
