import { Component, Inject } from '@angular/core';
import { DialogContainerComponent } from '../../../shared/components/general/dialog-container/dialog-container.component';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { DialogData } from '../../models/models';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [DialogContainerComponent, MatDialogModule],
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  confirm() {
    this.dialogRef.close(true);
  }
}
